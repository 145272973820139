<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			mobile-breakpoint="400"
			:loading="fetching"
			loading-text="Fetching users..."
			:footer-props="footerProps"
			:server-items-length="totalUsers"
			:options.sync="options"
			:item-class="rowBackground"
		>
			<template v-slot:top>
				<div class="table-header">
					<div class="table-row">
						<v-text-field
							v-model="search"
							label="Search user id"
							class="mx-4 pt-6"
							outlined
							clearable
						></v-text-field>
						<v-btn class="ml-2" icon @click="resetFetch" :loading="fetching">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
					</div>
					<div class="table-row search-options">
						<v-switch v-model="showAlts" label="Show Alts"></v-switch>
					</div>
				</div>
			</template>
			<template v-slot:item.fullUsername="{ item }">
				<div class="user-info">
					<v-avatar size="32">
						<v-img :src="item.avatarUrl" alt="avatar" />
					</v-avatar>
					<div class="user-info-name">
						{{ item.fullUsername }}
					</div>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip left>
					<template v-slot:activator="{on, attrs}">
						<span v-bind="attrs" v-on="on">
							<AdminUserReportModal :user="item" />
						</span>
					</template>
					<span v-html="getIp(item)"></span>
				</v-tooltip>
			</template>
		</v-data-table>
		<AdminUserReportModal v-if="overrideUser" :user="overrideUser" isOverride />
	</div>
</template>

<script>
import AdminUserReportModal from '@/views/admin/components/AdminUserReportModal';

export default {
	name: 'AdminReportPage',
	components: { AdminUserReportModal },
	data: () => ({
		fetching: true,
		totalUsers: 0,
		search: '',
		showAlts: false,
		users: [],
		overrideUser: null,
		headers: [
			{ text: 'Reports', value: 'reports', sortable: false },
			{ text: 'Username', value: 'fullUsername', sortable: false },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Action', value: 'action', sortable: false },
		],
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
		options: {
			page: 1,
			itemsPerPage: 25,
		},
	}),
	watch: {
		options: {
			handler() {
				this.fetchUsers();
			},
			deep: true,
		},
	},
	computed: {
		items() {
			return this.users.map((user) => {
				return this.formatUser(user);
			});
		},
	},
	methods: {
		getIp(user) {
			if (user.ips?.length) {
				return user.ips.join('<br>');
			}
		},
		rowBackground(user) {
			if (user.banned) {
				return 'banned-row';
			}
			return '';
		},
		formatUser(user) {
			return {
				id: user.discordId,
				avatar: user.avatar,
				avatarUrl: this.getAvatarUrl(
					user.discordId,
					user.discriminator,
					user.avatar
				),
				banned: user.banned,
				discriminator: user.discriminator,
				username: user.username,
				fullUsername: `${user.username}#${user.discriminator}`,
				reports: user.reportsAgainstCount,
				ips: user.ips
			};
		},
		getAvatarUrl(id, discriminator, avatar) {
			if (avatar) {
				return `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(discriminator) % 5
				}.png`;
			}
		},
		async resetFetch() {
			this.options.page = 1;
			await this.fetchUsers();
		},
		async fetchUsers() {
			try {
				this.fetching = true;
				this.users = await this.$store.dispatch('getUserReports', {
					page: this.options.page,
					userId: this.search,
					showAlts: this.showAlts,
					limit: this.options.itemsPerPage,
				});
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
		async getTotalUsers() {
			try {
				this.fetching = true;
				this.totalUsers = await this.$store.dispatch('getTotalUserReports');
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
	},
	async mounted() {
		await this.getTotalUsers();
		this.fetchUsers();
		if (this.$route.query.id) {
			try {
				let user = await this.$store.dispatch('getUserReports', {
					page: this.options.page,
					userId: this.$route.query.id,
					limit: this.options.itemsPerPage,
				});
				user = user[0];
				if (!user) return;
				this.overrideUser = this.formatUser(user);
			} catch (err) {
				console.error(err);
				return;
			}
		}
	},
};
</script>

<style scoped>
.user-info {
	display: flex;
}

.user-info-name {
	padding-top: 6px;
	padding-left: 10px;
}

.table-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 20px;
}

.table-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.search-options {
	padding-left: 20px;
	padding-top: 0px;
	margin-top: -30px;
}

::v-deep .banned-row {
	background-color: rgba(255, 0, 0, 0.2);
}
</style>
